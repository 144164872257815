import React from "react";
import logo from "../../assets/logo.svg";

import "./homepage.css";

const Home = () => {

  return (
    <div className="homePage">
      <img src={logo} alt="logo" className="logo" />
      <div className="intro">
        Hello, My Name is{" "}
        <h1 style={{ marginLeft: "15px" }}>
          <b>Voniel Brown</b>{" "}
        </h1>
      </div>
    </div>
  );
};

export default Home;
