import React from "react";
import { Skills } from "../../Components/Skills/Skills";
import "./contact.css";

const Contact = () => {

  return (
    <div className="contactPage">
      <h1 className="page-title" id="skills">
        Skills
      </h1>
      <div className="skills-component">
        <Skills />
      </div>
    </div>
  );
};

export default Contact;
